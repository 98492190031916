/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import { Link } from "gatsby"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faUserCircle, faDice, faCommentsDollar } from '@fortawesome/free-solid-svg-icons'
import { faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`
const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  padding:10px;
  border-radius:3px;
  margin-top:40px;
`

const List = styled.li`
  list-style-type:none;
`


const table_limit = () => {

useEffect(() => {
        window.scrollTo(0,0)
    })



 const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  tablesign1: file(relativePath: {eq: "tablesigns/tablesign1.jpg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
        
      }
    }
  },
  tablesign2: file(relativePath: {eq: "tablesigns/tablesign2.jpg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
        
      }
    }
  },
  image1: file(relativePath: {eq: "tablesigns/mgmgrand.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
        
      }
    }
  },
  image2: file(relativePath: {eq: "tablesigns/caesars.png"}) {
    childImageSharp {
      fluid(maxWidth:110){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  genesis: file(relativePath: {eq: "tablesigns/genesis.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  tekvisions: file(relativePath: {eq: "tablesigns/tekvisions.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  css: file(relativePath: {eq: "tablesigns/css.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  skagit: file(relativePath: {eq: "tablesigns/19943v.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  baymills: file(relativePath: {eq: "tablesigns/baymills.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  cromwell: file(relativePath: {eq: "tablesigns/cromwell.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  flamingo: file(relativePath: {eq: "tablesigns/flamingo.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  hardrock: file(relativePath: {eq: "tablesigns/hardrock.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  harrahs: file(relativePath: {eq: "tablesigns/harrahs.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  horseshoe: file(relativePath: {eq: "tablesigns/horseshoe.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  lodge: file(relativePath: {eq: "tablesigns/lodge.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  mgmgrand: file(relativePath: {eq: "tablesigns/mgmgrand.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  newyork: file(relativePath: {eq: "tablesigns/newyork.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  plaza: file(relativePath: {eq: "tablesigns/plaza.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  riverwind: file(relativePath: {eq: "tablesigns/riverwind.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  sugarhouse: file(relativePath: {eq: "tablesigns/sugarhouse.jpg"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  tulalip: file(relativePath: {eq: "tablesigns/tulalip.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  venetian: file(relativePath: {eq: "tablesigns/venetian.png"}) {
    childImageSharp {
      fluid(maxWidth:120){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  cache: file(relativePath: {eq: "tablesigns/cache.jpg"}) {
    childImageSharp {
      fluid(maxWidth:70){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)



 return(

 
	 <div id="outer-container">
	     <SEO/>
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <div id="page-wrap">
   
        <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
        <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
	      <h1  sx={{
	        color: "heading",
          m: 1,
          mt:80,
          mb:40
	      }}>Table Limit Displays</h1>

	      
	    

        <Img sx={{m:'0 auto'}}
            fluid={data.tablesign1.childImageSharp.fluid}
            alt="Table Limit Display at a casino."
            style={{ width:`100%`}} 
          />
	     
	      <div>
        <h3>Solution</h3>
			  <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
          Our table limit display solutions allow: </p>

          <ul  sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            <List> <FontAwesomeIcon icon={faUserFriends}/> Clients to easily understand table game rules</List>
            <List> <FontAwesomeIcon icon={faUserCircle}/> Operators to meet state gaming regulatory requirements </List>
            <List> <FontAwesomeIcon icon={faDice}/> Table game managers to quickly &  easily change the current game or game parameters </List>
            <List> <FontAwesomeIcon icon={faCommentsDollar}/> Marketing to cross, up and suggestive sell </List>
          </ul>

         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
          Every unit comes preconfigured with the operator’s custom game titles, rules, colors and min/max bet limits.
         </p>

         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}> 
          Included with your purchase is a private account to manage all advertising content. 
         </p>

         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
          Our units provide front touch access to change limits & lock limits. 
         </p>

         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
          With over 50% screen space dedicated to advertising, other casino offers & amenities, our solution is an excellent platform for suggestive selling to both a captive and transitory audience.  
         </p>

         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
          Just one 30-second video loop will play 86,400 times in one month on one table limit display, offering hundreds of thousands, if not millions of marketing impressions monthly.
         </p>

			  
		  </div>

      <Img
          fluid={data.tablesign2.childImageSharp.fluid}
          alt="Table Limit Display at a casino."
          style={{ width:`100%`}} 
       />

		  <div>
		  	<h3>Useful to</h3>
        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>Casinos and cardrooms</p>
		  	<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
		  		
Casinos all over the America’s and some in Asia use our displays. All nine Caesars properties on the Las Vegas strip choose this solution exclusively offered by our partner, Casino Supplies & Services. 

		  	</p>



        <h3>Get Connected</h3>
        <a href="https://tablesign.com">Tablesign.com</a>

      
		  </div>

      

      <ImageWrapper>
           <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="mgm grand casino"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="Caesars Entertainment" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.genesis.childImageSharp.fluid}
            alt="genesis gaming" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.tekvisions.childImageSharp.fluid}
            alt="tekvisions" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.css.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.venetian.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.tulalip.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.sugarhouse.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.riverwind.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.plaza.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.newyork.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.lodge.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.horseshoe.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.harrahs.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.hardrock.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.flamingo.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.cromwell.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.baymills.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
         <Img
            fluid={data.skagit.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.cache.childImageSharp.fluid}
            alt="casino supplies and services" 
            style={{ width:`100%`}}
          />

        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>
        </div>
     </div>   
    </div>
   
    
)
}



export default table_limit


